_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "Podaj pasuj\u0105c\u0105 warto\u015b\u0107 dla {0}",
	"crm.label.field":"Pole",//no i18n
"crm.label.value":"Warto\u015b\u0107",//no i18n
	"sentiment.criteria.wrongcriteria":"Warto\u015b\u0107 kryteri\u00f3w nie mo\u017ce przekracza\u0107 {0}",//no i18n
"crm.mb.field.common.splc":"Znaki specjalne s\u0105 niedozwolone. Wprowad\u017a prawid\u0142ow\u0105 warto\u015b\u0107.",//no i18n
	"crm.label.field.plural":"pola",//no i18n
	"crm.label.in.minutes":"{0} (w minutach)",//no i18n
	"crm.security.roles.list":"Lista r\u00f3l",//no i18n
"crm.security.roles.lookup.info":"Prosz\u0119 wybra\u0107 jedn\u0105 rol\u0119 z\u00a0listy.",//no i18n
"crm.territory.addterritory":"Dodaj terytorium",//no i18n
"crm.title.edit.territory":"Edytuj Terytorium",//no i18n
"crm.territory.title.assign.territories":"Przypisz terytoria",//no i18n
	"crm.label.context.help":"Pomoc",//no i18n
	"crm.label.from":"Od",//no i18n
"crm.label.to":"do",//no i18n
	"workflow.option.webhookFailure.fromDate":"Data rozpocz\u0119cia",//no i18n
"workflow.option.webhookFailure.toDate":"Data zako\u0144czenia",//no i18n
"crm.custom.field.less.than.equalto":"{0} musi wynosi\u0107 mniej ni\u017c lub by\u0107 r\u00f3wne {1}.",//no i18n
	"crm.template.listview.search.no.results":"Brak wynik\u00f3w",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"Data rozpocz\u0119cia nie mo\u017ce by\u0107 p\u00f3\u017aniejsza ni\u017c data zako\u0144czenia.",//no i18n
	"crm.label.tag.new":"Nowy tag",//No I18n
	"crm.label.enter.tag":"Wprowad\u017a tagi",//No I18n
	"crux.comboBox.max.limit":"Nie mo\u017cna wybra\u0107 wi\u0119cej ni\u017c {0} {1}.",//NO I18n
	"Administrator":"Administrator",//No I18n
	"Standard":"Standardowy",//No I18n
	"crm.button.add":"Dodaj",//NO I18n
	"crm.label.users":"U\u017cytkownicy", //NO I18n
  "crm.workflow.alert.roles":"Role", //NO I18n
  "crm.security.groups":"Grupy", //NO I18n
	"crm.label.available" : "Dost\u0119pny", //NO I18n
	"crm.label.assign.manually" : "Przypisz", //NO I18n
	"crm.globalsearch.option.all": "Wszystkie", //NO I18n
	"webform.status.Active":"Aktywny", //NO I18n
	"Inactive":"Nieaktywny", //NO I18n
  "Confirmed":"Potwierdzony", //NO I18n
  "crm.user.component.unconfirmed":"Nie potwierdzono",//no i18n
  "DeletedUser":"Usuni\u0119te", //NO I18n
  "crm.feed.group.admin":"Administrator", //NO I18n
  "crm.ln.lable.current":"Bie\u017c\u0105ce", //NO I18n
	"crm.label.selected": "Wybrany",//NO I18n
	"crm.auditlog.user": "U\u017cytkownik", //NO I18n
	"cob.role": "Rola", //NO I18n
	"zoho.email": "E-mail", //NO I18n
	"Profile": "Profil", //NO I18n
	"crm.security.group.users.empty": "Nie znaleziono \u017cadnych u\u017cytkownik\u00f3w.", //NO I18n
	"crm.label.picklist.none": "Brak", //NO I18n
	"crm.usrpop.non.selected" : "Wybrani u\u017cytkownicy",//NO I18n
	"crm.zti.label.user": "Nazwa u\u017cytkownika", //NO I18n
	"crm.label.notSelected" : "Nie wybrano",//NO I18n
	"AM" : "Przed po\u0142udniem",//NO I18n
	"Call" : "Po\u0142\u0105czenie",//NO I18n
	"crm.phoneNo.Link.Title" : "Zadzwo\u0144 poprzez Skype",//NO I18n
	"crm.button.cancel" : "Anuluj",//NO I18n
	"crm.button.save" : "Zapisz",//NO I18n
	"crm.no.data.found" : "Nie znaleziono danych.",//NO I18n
	"crm.label.no.options.found" : "Nie znaleziono opcji.",//No I18n
	"crm.globalsearch.search.title" : "Wyszukaj",//No I18n
	"None" : "Brak",//No I18n
	"crm.label.criteria.pattern" : "Wzorzec kryteri\u00f3w",//No I18n
	"crm.label.edit.criteria.pattern" : "Edytuj wzorzec",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "Wz\u00f3r nawias\u00f3w nie pasuje.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Niepoprawne nawiasy otaczaj\u0105 operatory warunkowe.",//No I18n
	"crm.criteria.number.notmatch.check" : "Sprawd\u017a wzorzec w {0}.",//No I18n
	"criteria.error.alert.other.params" : "Ten wzorzec zawiera nieprawid\u0142ow\u0105 tre\u015b\u0107.", //No I18n
	"crm.label.search.for.users": "Wyszukaj u\u017cytkownik\u00f3w", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "Wzorzec kryteri\u00f3w nie odpowiada wybranym przez Ciebie warunkom.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "Wzorzec kryteri\u00f3w nie odpowiada wybranym przez Ciebie warunkom.", //No I18n
	"and" : "i", //No I18n
	"or" : "lub", //No I18n
	"crm.label.or" : "LUB", //No I18n
	"crm.label.and" : "I", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Podaj prawid\u0142ow\u0105 etykiet\u0119 pola w wierszu {0}.", //No I18n
	"crm.criteria.condition.valid.check" : "Okre\u015bl prawid\u0142owy warunek dla {0}.", //No I18n
	"crm.field.valid.check" : "Wprowad\u017a prawid\u0142owy {0}.", //No I18n
	"crm.custom.field.less.than.to" : "Zakres <i>Od</i> nie mo\u017ce by\u0107 wi\u0119kszy od zakresu <i>Do</i>.", //No I18n
	"crm.alert.label.savepattern" : "Zapisz wzorzec przed zmian\u0105 kryteri\u00f3w.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "Nie mo\u017cesz doda\u0107 dodatkowych kryteri\u00f3w.",//No I18n
	"is" : "jest",//No I18n
	"isn\'t" : "nie jest",//No I18n
	"contains" : "zawiera",//No I18n
	"doesn\'t contain" : "nie zawiera",//No I18n
	"starts with" : "zaczyna si\u0119 od",//No I18n
	"ends with" : "ko\u0144czy si\u0119 na",//No I18n
	"is empty" : "jest puste",//No I18n
	"is not empty" : "nie jest puste",//No I18n
	"is before" : "jest przed",//No I18n
	"is after" : "jest po",//No I18n
	"between" : "pomi\u0119dzy",//No I18n
	"not between" : "nie pomi\u0119dzy",//No I18n
	"Today" : "Dzisiaj",//No I18n
	"Tommorow" : "Jutro",//No I18n
	"Tommorow Onwards" : "Obowi\u0105zuje od jutra",//No I18n
	"Yesterday" : "Wczoraj",//No I18n
	"Till Yesterday" : "Do wczoraj",//No I18n
	"Last Month" : "W ubieg\u0142ym miesi\u0105cu",//No I18n
	"Current Month" : "Bie\u017c\u0105cy miesi\u0105c", //No I18n
	"Next Month" : "Nast\u0119pny miesi\u0105c", //No I18n
	"Last Week" : "W ubieg\u0142ym tygodniu", //No I18n
	"Current Week" : "Bie\u017c\u0105cy tydzie\u0144", //No I18n
	"Next Week" : "Nast\u0119pny tydzie\u0144", //No I18n
	"Age in Days" : "Wiek w dniach", //No I18n
	"Due in Days" : "Liczba dni do zako\u0144czenia", //No I18n
	"Scheduled" : "Zaplanowane", //No I18n
	"Attended Dialled" : "Wybrane zrealizowane", //No I18n
	"Unattended Dialled" : "Wybrane niezrealizowane", //No I18n
	"Overdue" : "Przeterminowane", //No I18n
	"Cancelled" : "Anulowane", //No I18n
	"Received" : "Odebrano", //No I18n
	"Missed" : "Nieodebrane", //No I18n
	"crm.alert.character.not.allowed" : "{0} dost\u0119p zabroniony", //No I18n
	"crm.condition.in.last" : "przez ostatni(e):", //No I18n
	"crm.zinvoice.dueIn" : "spowodowane w", //No I18n
	"on" : "W",//No I18n
	"before" : "wcze\u015bniej",//No I18n
	"crm.label.general.small.after" : "po",//No I18n
	"crm.thisweek" : "Ten tydzie\u0144",//No I18n
	"crm.label.this.month" : "Bie\u017c\u0105cy miesi\u0105c",//No I18n
	"crm.thisyear" : "Ten rok",//No I18n
	"crm.source.user.and.system" : "U\u017cytkownik i system",//No I18n
	"crm.source.user.or.system" : "U\u017cytkownik lub system",//No I18n
	"crm.label.system2" : "System",//No I18n
	"crm.source.user.only" : "Tylko przez u\u017cytkownika",//No I18n
	"crm.source.system.only" : "Tylko przez system",//No I18n
	"crm.condition.till.today" : "Do dnia dzisiejszego",//No I18n
	"game.month.left" : "1 miesi\u0105c",//No I18n
	"game.months.left" : "{0} miesi\u0119cy",//No I18n
	"crm.condition.last.30.days" : "w ci\u0105gu ostatnich 30 dni",//No I18n
	"crm.condition.last.60.days" : "w ci\u0105gu ostatnich 60 dni",//No I18n
	"crm.condition.last.90.days" : "w ci\u0105gu ostatnich 90 dni",//No I18n
	"crm.label.filter.typehere" : "Wpisz tutaj", //No I18N
	"crm.filter.is.not" : "nie jest", //No I18n
	"crm.condition.until.now" : "Do teraz",//No I18n
	"crm.filter.email.isblocked" : "jest blokowany",//No I18n
	"crm.filter.email.isnotblocked" : "nie jest blokowany",//No I18n
	"crm.label.no.results.match" : "Nie ma pasuj\u0105cych wynik\u00f3w",//No I18n
	"crm.label.select.user" : "Kliknij, aby wybra\u0107 u\u017cytkownik\u00f3w.", //No I18n
	"current.logged.in.user": "Zalogowany u\u017cytkownik", //NO I18n
	"current.logged.in.user.definition": "U\u017cytkownik, kt\u00f3ry zainicjowa\u0142 dzia\u0142anie rekordu.", //NO i18n
	"crm.security.group": "Grupa", //NO I18n
	"crm.security.role": "Rola", //NO I18n
	"Date" : "Data",//No I18n
	"crm.field.valid.decimal.check2" : "Miejsca dziesi\u0119tne dla pola <i>{0}</i> powinny by\u0107 mniejsze lub r\u00f3wne {1}.",//No I18n
	"crm.field.empty.check" : "Pole {0} nie mo\u017ce by\u0107 puste.",//No I18n
	"crm.label.add.note": "Dodaj notatk\u0119", //NO I18n
	"crm.label.simply.by": "przez", //NO I18n
	"crm.general.addnote": "Dodaj notatk\u0119", //NO I18n
	"crm.general.addtitle": "Dodaj tytu\u0142", //NO I18n
	"crm.label.attach.file": "Do\u0142\u0105cz Plik", //NO I18N
	"crm.button.mass.delete": "Usu\u0144", //NO I18N
	"crm.warning.delete.record": "Czy na pewno chcesz usun\u0105\u0107 \u201e{0}\u201d?", //NO I18N
	"crm.label.yes": "Tak", //NO I18N
	"crm.note.view.previous": "Wy\u015bwietl poprzednie notatki", //NO I18N
  "of": "z", //NO I18N
	"crm.label.notes": "Notatki", //NO I18N
	"crm.note.recent.first": "Najnowszy pierwszy", //NO I18N
	"crm.note.recent.last": "Najnowszy ostatni", //NO I18N
	"crm.territory.label.only": "{0} tylko", //no i18n
	"crm.select" : "Wybierz",//No I18n
	"crm.button.apply.filter" : "Zastosuj Filtr",//No I18n
	"crm.alert.maximum.text.values.contains" : "Nie mo\u017cesz doda\u0107 wi\u0119cej ni\u017c {0} warto\u015bci dla tego pola.",//No I18n
	"PM" : "Po po\u0142udniu",//No I18n
	"crm.mb.newversion.msg4" : "OK, rozumiem!",//No I18n
	"Jan" : "Sty",//No I18n
	"Feb" : "Lut",//No I18n
	"Mar" : "Mar",//No I18n
	"Apr" : "Kwi",//No I18n
	"Jun" : "Cze",//No I18n
	"Jul" : "Lip",//No I18n
	"Aug" : "Sie",//No I18n
	"Sep" : "Wrz",//No I18n
	"Oct" : "Pa\u017a",//No I18n
	"Nov" : "Lis",//No I18n
	"Dec" : "Gru",//No I18n
	"crm.label.More" :"Wiecej", //no i18n

	"crm.label.unmapped.stages":"Nieuwzgl\u0119dnione", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "Poka\u017c wi\u0119cej",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Poka\u017c mniej",//No I18n

	 	 //filter related keys-start
	"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
	"crm.lead.prediction.tooltip.convert":"PRAWDOPODOBIE\u0143STWO",//no i18n
	"crm.lead.prediction.tooltip.score":"WYNIK",//no i18n
	"Planned":"Zaplanowany",//no i18n
	"Invited":"Zaproszony",//no i18n
	"Sent":"Przes\u0142ane",//no i18n
	"Received":"Odebrano",//no i18n
	"Opened":"Otwarty",//no i18n
	"Responded":"Wiadomo\u015b\u0107 przes\u0142ana",//no i18n
	"Bounced":"Odrzucony",//no i18n
	"Opted\ Out":"Rezygnacja",//no i18n
	"crm.filter.label.with.open":"Z otwartym {0}",//no i18n
	"crm.filter.label.without.open":"Bez otwierania {0}",//no i18n
	"crm.filter.label.without.any":"Bez {0}",//no i18n
	"crm.filter.label.with.module":"Z {0}",//no i18n
	"crm.filter.label.activity.due":"Z terminem do {0}",//no i18n
	"crm.filter.label.activity.done":"Gotowe {0}",//no i18n
	"Notes":"Komentarze",//no i18n
	"crm.filter.label.notes.added":"Dodano uwagi",//no i18n
	"crm.label.filter.email.status":"Status najnowszej wiadomo\u015bci e-mail",//no i18n
	"crm.label.filter.email.clicked":"klikni\u0119te",//no i18n
	"crm.label.filter.email.responded":"z udzielon\u0105 odpowiedzi\u0105", //no i18n
	"crm.label.filter.email.info":"Filtruj rekordy na podstawie najnowszego stanu wys\u0142anych/odebranych wiadomo\u015bci e-mail.",//no i18n
	"crm.filter.label.sent":"wys\u0142ano",//no i18n
	"crm.filter.label.not.sent":"nie wys\u0142ano",//no i18n
	"crm.filter.label.opened":"otwarto",//no i18n
	"crm.filter.label.not.opened":"nie otwarto",//no i18n
	"crm.filter.label.received":"otrzymano",//no i18n
	"crm.filter.label.not.received":"nie otrzymano",//no i18n
	"crm.filter.label.bounced":"zwr\u00f3cono",//no i18n
	"crm.filter.label.opened.not.replied":"otwarto i nie odpowiedziano", //no i18n
	"crm.filter.label.any":"Dowolna z powy\u017cszych opcji",//no i18n
	"crm.zia.config.potential.amount":"Kwota {0}",//no i18n
	"Quote\ Stage":"{0} Etap",//no i18n
	"crm.module.owner":"{0} W\u0142a\u015bciciel",//no i18n
	"Potential\ Closing\ Date":"{0} data zako\u0144czenia",//no i18n
	"crm.lead.prediction.likely.convert":"Z mo\u017cliwo\u015bci\u0105 konwersji",//no i18n
	"crm.lead.prediction.convert.high":"Wysoki",//no i18n
	"crm.lead.prediction.convert.medium":"\u015aredni",//no i18n
	"crm.lead.prediction.convert.low":"Niski",//no i18n
	"crm.predictions.feature.label":"Przewidywanie",//no i18n
	"crm.intelligence.prediction.likelywin":"Du\u017ce prawdopodobie\u0144stwo wygranej",//no i18n
	"crm.intelligence.prediction.likelylose":"Prawdopodobie\u0144stwo przegranej",//no i18n
	"crm.intelligence.prediction.halfchance":"Szansa 50:50",//no i18n
	"crm.intelligence.prediction.score":"Przewidywany wynik",//no i18n
	"crm.lead.prediction.recent.score":"Ostatnio przewidziany wynik",//no i18n
	"crm.intelligence.prediction.lastconv":"3 ostatnie konwersacje",//no i18n
	"crm.intelligence.prediction.recordsfocus":"Istotne rekordy",//no i18n
	"crm.intelligence.prediction.slowmoving":"Wolny ruch",//no i18n
	"crm.intelligence.prediction.trend.down":"Trac\u0105ce ostatnio popularno\u015b\u0107",//no i18n
	"crm.label.touched.records":"Zmodyfikowane rekordy",//no i18n
	"crm.label.untouched.records":"Niezmodyfikowane rekordy",//no i18n
	"crm.label.record.action":"Akcja rekordu",//no i18n
	"workflow.rule.view.label.Modified":"Zmodyfikowany",//no i18n
	"crm.label.not.modified":"Niemodyfikowane",//no i18n
	"crm.label.related.records.action":"Powi\u0105zane akcje rekordu",//no i18n
	"Done":"Zrobione",//no i18n
	"crm.label.not.done":"Nie wykonano",//no i18n
	"sentiment.model":"Opinia e-mail",//no i18n
	"sentiment.criteria.count":"Liczba",//no i18n
	"sentiment.criteria.percentage":"Warto\u015b\u0107 procentowa",//no i18n
	"sentiment.criteria.lastmail":"Dla ostatniej wiadomo\u015bci e-mail",//no i18n
	"Chats":"Rozmowy",//no i18n
	"Attended":"Przeprowadzone",//no i18n
	"crm.lead.prediction.popup.text":"Przy prawdopodobie\u0144stwie konwersji \u201e{0}\u201d przewidywany wynik powinien wynosi\u0107 mi\u0119dzy {1}.",//no i18n
	"crm.lead.prediction.popup.final":"Zmie\u0144 filtr na odpowiedni i spr\u00f3buj ponownie.",//no i18n
	"crm.custom.field.less.than.to1":"Zakres od nie mo\u017ce by\u0107 wi\u0119kszy od zakresu do.",//no i18n
	"Last\ Activity\ Date":"Data ostatniej aktywno\u015bci",//no i18n
	"crm.label.vendor.name":"{0} - nazwa",//no i18n
	"hours":"godz.",//no i18n
	"days":"dni",//no i18n
	"weeks":"tygodnie",//no i18n
	"months":"miesi\u0105ce",//no i18n
	"years":"lata",//no i18n
	"crm.label.general.small.after":"po",//no i18n
	"Last\ Week":"W ubieg\u0142ym tygodniu",//no i18n
	"Last\ Month":"W ubieg\u0142ym miesi\u0105cu",//no i18n
	"crm.module.name":"{0} - nazwa",//no i18n
	"Campaign":"Kampania",//no i18n
	"Tasks":"Zadania",//no i18n
	"Calls":"Rozmowy",//no i18n
	"Events":"Wydarzenia",//no i18n
	"sentiment.criteria.wrongcriteria":"Warto\u015b\u0107 kryteri\u00f3w nie mo\u017ce przekracza\u0107 {0}",//no i18n
	"crm.chosen.minimum.input.text":"Nale\u017cy wprowadzi\u0107 co najmniej {0} znak\u00f3w",//no i18n
	"crm.intelligence.prediction.trendup":"Wzrost popularno\u015bci",//no i18n
	"crm.intelligence.prediction.trenddown":"Spadek popularno\u015bci",//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"Uko\u0144czone" ,//no i18n
	"crm.label.success":"Sukces" ,//no i18n
	"crm.label.Failure":"Niepowodzenie" ,//no i18n
	"Both":"Oba" ,//no i18n
	"crm.condition.cannot.empty":"Warunki nie mog\u0105 by\u0107 puste.",//no i18n
	"crm.condition.last.30.days":"w ci\u0105gu ostatnich 30 dni",//no i18n
	"crm.condition.last.60.days":"w ci\u0105gu ostatnich 60 dni",//no i18n
	"crm.condition.last.90.days":"w ci\u0105gu ostatnich 90 dni",//no i18n
	"crm.sentiment.Positive":"Pozytywne",//no i18n
	"crm.sentiment.Negative":"Negatywne",//no i18n
	"sentiment.positiveandnegative":"Dodatnie i ujemne",//no i18n
	"sentiment.positiveornegative":"Dodatnie lub ujemne",//no i18n
	"sentiment.positiveonly":"Tylko dodatnie",//no i18n
	"sentiment.negativeonly":"Tylko ujemne",//no i18n
	"crm.sentiment.Neutral":"Neutralne",//no i18n
	"crm.filters.select.campaign.type":"Wybierz typ {0}",//no i18n
	"crm.filters.select.campaign.status":"Wybierz stan {0}",//no i18n
	"campaign.Member" : "Cz\u0142onek",//no i18n
	"Service":"Us\u0142uga",//no i18n
	"Activities":"Dzia\u0142ania",//no i18n
	"crm.livedesk.pot.nextdays":"Nast\u0119pnych {0} dni",//no i18n
	"Today\ +\ Overdue":"Dzisiejsze + Przeterminowane",//no i18n
	"crm.source.user.and.system":"U\u017cytkownik i system",//no i18n
	"crm.source.user.or.system":"U\u017cytkownik lub system",//no i18n
	"User":"U\u017cytkownik",//no i18n
	"crm.source.user.only":"Tylko przez u\u017cytkownika",//no i18n
	"crm.source.system.only":"Tylko przez system",//no i18n
	"Scheduled":"Zaplanowane",//no i18n
	"Attended\ Dialled":"Wybrane zrealizowane",//no i18n
	"Unattended\ Dialled":"Wybrane niezrealizowane",//no i18n
	"Cancelled":"Anulowane",//no i18n
	"crm.filter.email.isblocked":"jest blokowany",//no i18n
	"crm.filter.email.isnotblocked":"nie jest blokowany",//no i18n
	"condition.till.now":"Do teraz",//no i18n
	"crm.recurring.no.months":"{0} miesi\u0105ce (miesi\u0119cy)",//no i18n
	"crm.lead.prediction.tooltip":"Z mo\u017cliwo\u015bci\u0105 konwersji \u2014 zakres wyniku",//no i18n
	"crm.website.activity":"Aktywno\u015b\u0107 na stronie internetowej",//no i18n
	"crm.label.By":"Przez",//no i18n
	"crm.chosen.searching.text":"Wyszukiwanie...",//no i18n
	"crm.label.memberstatus.is":"i stan cz\u0142onka to",//no i18n
	"crm.events.duration":"Czas trwania",//no i18n
	"crm.title.clear.name":"Wyczy\u015b\u0107",//no i18n
	"crm.label.status.is":"i\u00a0status to",//no i18n
	"zia.last3.help":"Konwersacja obejmuje po\u0142\u0105czenia, zadania, {0}, otrzymane wiadomo\u015bci e-mail, notatki, wizyty, komentarze w mediach spo\u0142eczno\u015bciowych, \u017c\u0105dania pomocy technicznej z Desk.",//no i18n
	"crm.label.tag.related.to":"zwi\u0105zane z",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "Zostanie utworzony nowy {0}.",//No I18n
	"crm.krp.no.records.found" : "Nie znaleziono \u017cadnych {0}",//No I18n
	"crm.module.new" : "Nowy {0}",//No I18n
	"crm.label.view" : "Widok",//No I18n
	"crm.nsocial.customers" : "Klienci",//No I18n
	"crm.nsocial.open.potential" : "Otwarte {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Inni",//No i18n
	"crm.field.length.check" : "Warto\u015b\u0107 {0} przekracza maksymaln\u0105 d\u0142ugo\u015b\u0107.", //No I18n
	"crm.lower.now": "teraz",//no i18n
	"crm.time.min.ago": "{0} min temu",//no i18n
	"crm.time.mins.ago":"{0} min temu",//no i18n
	"crm.time.hr.ago": "{0} godz. temu",//no i18n
	"crm.time.hrs.ago": "{0} godz. temu", //no i18n
	"AllUsers": "Wszyscy u\u017cytkownicy", //no i18n
	"crm.label.search":"Wyszukaj",//no i18n
	"crm.api.filterby":"Filtruj wed\u0142ug",//no i18n
	"crm.customview.nofields.found":"--Brak pasuj\u0105cych p\u00f3l--",//no i18n
	"crm.setup.system.ziarecommendation":"Rekomendacja",//no i18n
	"crm.filter.label.all.products":"Wszystkie {0}",//no i18n
	"crm.filter.label.select.products":"Wybrane {0}",//no i18n
	"crm.reviewprocess.smart.filter":"Stan rekordu w procesie recenzowania",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Wybierz warto\u015b\u0107.",//no i18n
	"crm.segmentation.segment.score":"Wynik segmentu",//no i18n
	"crm.filter.label.in":"na podstawie",//no i18n
	"crm.filter.label.and.purchase.in":"i prawdopodobnie kupi w",//no i18n
	"crm.filter.label.last.purchased":"i niedawno kupi\u0142(a)",//no i18n
	"crm.filter.label.a.day":"dziennie",//no i18n
	"crm.filter.label.a.week":"tygodniowo",//no i18n
	"crm.filter.label.a.month":"miesi\u0119cznie",//no i18n
	"crm.cal.custom":"Niestandardowy",//no i18n
	"crm.mb.field.common.empt":"Warto\u015b\u0107 nie mo\u017ce by\u0107 pusta.",//no i18n
	"crm.chosen.error.loading.text":"Nie mo\u017cna za\u0142adowa\u0107 wynik\u00f3w",//no i18n
	"crm.filter.label.firstbuy":"Pierwszy raz",//no i18n
	"crm.filter.label.cwbab":"Zale\u017cno\u015b\u0107",//no i18n
	"crm.filter.label.fbt":"Pakiet",//no i18n
	"crm.filter.label.rebuy":"Powt\u00f3rz",//no i18n
	"crm.filter.label.nextbuy":"Sekwencja",//no i18n
	"crm.label.search":"Pesquisar",//no i18n
	"crm.api.filterby":"Filtrar por",//no i18n
	"crm.customview.nofields.found":"--Nenhum campo correspondente--",//no i18n
	"crm.setup.system.ziarecommendation":"Recomenda\u00e7\u00e3o",//no i18n
	"crm.filter.label.all.products":"Todos os {0}",//no i18n
	"crm.filter.label.select.products":"{0} selecionado",//no i18n
	//"crm.reviewprocess.smart.filter":"Status do registro processo de revis\u00e3o",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Escolha um valor.",//no i18n
	//"crm.segmentation.segment.score":"Pontua\u00e7\u00e3o do segmento",//no i18n
	"crm.filter.label.in":"baseado em",//no i18n
	"crm.filter.label.and.purchase.in":"e propenso a comprar em",//no i18n
	"crm.filter.label.last.purchased":"e recentemente adquiriu",//no i18n
	"crm.filter.label.a.day":"um dia",//no i18n
	"crm.filter.label.a.week":"uma semana",//no i18n
	"crm.filter.label.a.month":"um m\u00eas",//no i18n
	"crm.cal.custom":"Personalizado",//no i18n
	"crm.mb.field.common.empt":"O valor n\u00e3o pode ficar em branco.",//no i18n
	"crm.chosen.error.loading.text":"Ops, n\u00e3o foi poss\u00edvel carregar seus resultados",//no i18n
	"crm.filter.label.firstbuy":"Primeira vez",//no i18n
	"crm.filter.label.cwbab":"Dependente",//no i18n
	"crm.filter.label.fbt":"Agrupamento",//no i18n
	"crm.filter.label.rebuy":"Repetir",//no i18n
	"crm.filter.label.nextbuy":"Sequ\u00eancia",//no i18n
	"crm.mxnlookup.select" : "Przypisz {0}",//No I18n
	"crm.lookup.chooserecord":"Wybierz {0}",//no i18n
	"crm.record.selected":"Wybrane {0}",//no i18n
	"crm.module.empty.message" : "{0} - nie znaleziono",//No I18n
	"crm.mxnlookup.selected" : "Przypisano {0}",//No I18n
	"crm.label.creator.noPermission" : "Odmowa dost\u0119pu", //No I18n
	"crm.security.error" : "Nie masz wystarczaj\u0105cych uprawnie\u0144 do wykonania tej operacji. Skontaktuj si\u0119 z administratorem.", //No I18n
	"crm.segmentation.recency" : "Czas od ostatniego zakupu", //No I18n
	"crm.segmentation.frequency" : "Cz\u0119stotliwo\u015b\u0107 zakup\u00f3w", //No I18n
	"crm.segmentation.monetary" : "Ilo\u015b\u0107 wydanych pieni\u0119dzy", //No I18n
	"crm.smartfilter.related.module.msg" : "Nie mo\u017cna zaznaczy\u0107 wi\u0119cej ni\u017c trzech powi\u0105zanych modu\u0142\u00f3w." , //No I18n
	"crm.smartfilter.related.module.msg1" : "(np. wiadomo\u015bci e-mail, dzia\u0142ania, uwagi)" , //No I18n
	"crm.smartfilter.related.module.msg2" : "Czas trwania nie mo\u017ce by\u0107 pusty", //No I18n
	"crm.label.timeZone": "Strefa czasowa", //NO I18n
	"crm.label.insufficient.privileges": "Niewystarczaj\u0105ce uprawnienia do wykonania tej operacji. Skontaktuj si\u0119 z administratorem.", //NO I18n
	"crm.filter.header.secton.system": "Filtry zdefiniowane systemowo", //NO I18N
	"crm.filter.header.secton.fields": "Filtruj wed\u0142ug p\u00f3l", //NO I18N
	"crm.createfield.calcinfo.new" : "To pole dzia\u0142a jak kalkulator dla ka\u017cdego wprowadzonego wyra\u017cenia.</br> <b>Przyk\u0142adowo 20+20</b> automatycznie da wynik <b>40</b>",//No i18n
	"crm.lable.read.only" : "Pole tylko do odczytu",//No i18n
	"crm.column.sort.asc" : "Rosn\u0105co",//No I18n
	"crm.column.sort.desc" : "Malej\u0105co",//No i18n
	"crm.column.unsort" : "Cofnij sortowanie",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Udost\u0119pnij klientowi", //NO I18N
	"crm.label.edited": "Edited", //NO I18N
	"crm.label.edited.on": "Edited on", //NO I18N
	"crm.message.limit.exceed": "Tylko {0} znaki s\u0105 dozwolone dla {1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Udost\u0119pnione klientowi", //NO I18N
	"crm.button.ok" : "OK", //NO I18N
	"crm.role.already.selected" : "Ta rola zosta\u0142a ju\u017c wybrana", //no i18n
	"crm.user.deleted": "U\u017bYTKOWNIK ZOSTA\u0141 USUNI\u0118TY",  //NO I18N
	"crm.account.closed": "TO KONTO ZOSTA\u0141O ZAMKNI\u0118TE",  //NO I18N
	"crm.start.chat": "Rozpocznij rozmow\u0119",  //NO I18N
	"crm.start.call": "Rozpocznij po\u0142\u0105czenie",  //NO I18N
	"crm.recipient.invalid.email" : "Znaleziono nieprawid\u0142owe adresy e-mail.", //NO I18N
	"crm.recipient.add.recipient" : "Dodaj dodatkowego odbiorc\u0119", //NO I18N
	"crm.start.video.call": "Rozpocznij wideorozmow\u0119",  //NO I18N //ignorei18n_start

	"Score":"Wynik",
	"Positive Score":"Wynik pozytywny",
	"Negative Score":"Wynik negatywny",
	"Touch Point Score":"Wynik punktu styczno\u015bci klient\u00f3w z\u00a0firm\u0105",
	"Positive Touch Point Score":"Pozytywny wynik punktu styczno\u015bci klient\u00f3w z\u00a0firm\u0105",
	"Negative Touch Point Score":"Negatywny wynik punktu styczno\u015bci klient\u00f3w z\u00a0firm\u0105",
	"crm.label.scoring.rules":"Zasady punktacji",
	"crm.label.type.minutes": "Wprowad\u017a tutaj dane w ci\u0105gu kilku minut", //NO I18N

	"is\ OPEN":"OTWARTE",//no i18n
	"is\ WON":"WYGRANE",//no i18n
	"is\ LOST":"PRZEGRANE",//no i18n
	"crm.potential.all.open":"Wszystkie otwarte etapy",//no i18n
	"crm.potential.all.won":"Wszystkie zamkni\u0119te wygrane etapy",//no i18n

	"crm.potential.all.lost":"Wszystkie zamkni\u0119te przegrane etapy",//no i18n
	"crm.campaign.member.status" : "Status Cz\u0142onka",//no i18n
	"crm.dashboard.select.type" : "Zaznacz {0}",//no i18n
	"crm.campaign.service.status":"Stan us\u0142ugi",//no i18n

	"crm.label.addColumn":"Dodaj kolumn\u0119",//no i18n
	"crm.button.clear.filter":"Zamknij filtr",//no i18n
	"crm.button.show.filter":"Poka\u017c filtr",//no i18n
	"crm.las.error.user.maxlimit":"Mo\u017cna wybra\u0107 maksymalnie 20 u\u017cytkownik\u00f3w.",//no i18n
	"crm.las.error.picklist.maxlimit":"Mo\u017cna wybra\u0107 maksymalnie 20 opcji.",//no i18n

	"crm.fileuploader.message.responseerror": "Przesy\u0142anie nie powiod\u0142o si\u0119", //NO I18N
	"crm.storage.create.error":"Nie mo\u017cna tworzy\u0107 \u017cadnych nowych rekord\u00f3w, poniewa\u017c osi\u0105gni\u0119to maksymalny limit przestrzeni w magazynie danych.",//no i18n
	"crm.storage.create.error.client":"Nie mo\u017cna tworzy\u0107 nowych rekord\u00f3w, poniewa\u017c Tw\u00f3j administrator osi\u0105gn\u0105\u0142 maksymalny limit przestrzeni dyskowej. Skontaktuj si\u0119 z {0}, aby rozwi\u0105za\u0107 ten problem.",//no i18n
	"crm.storage.avail.info":"(pozosta\u0142o {0} z {1})",//no i18n
	"crm.storage.error.key.manage":"Zarz\u0105dzaj swoim magazynem danych",//no i18n
	"Records":"Rekordy",//no i18n
	"crm.workflow.alert.additional.recipients" : "Dodatkowi Odbiorcy", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Oddziel dodatkowe adresy e-mail przecinkiem (,).", //NO I18N
	"crm.related.contact.account" : "{0} powi\u0105zane z {1}",//No I18n
	"crm.allcontact.show" : "Wszystkie {0}",//No I18n
	"crm.button.mass.show" : "Poka\u017c",//No I18n
	"crm.msg.custom.view.not.replied" : "Wiadomo\u015bci, na kt\u00f3re nie odpowiedziano", //NO I18N
	"crm.msg.custom.view.replied" : "Wiadomo\u015b\u0107, na kt\u00f3re odpowiedziano",//NO I18N
	"crm.workflow.select.recipients" : "Wybierz Odbiorc\u00f3w", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"Wybierz co najmniej jeden profil.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "Nie mo\u017cna usun\u0105\u0107 profilu domy\u015blnego.",//NO I18N
	"crm.inv.label.add.emails" : "Dodaj adresy e-mail", //NO I18N
	"crm.prediction.analytics.filter.year":"Poprzedni rok",//no i18n
	"Previous\ FY":"Poprzedni rok podatkowy",//no i18n
	"Current\ FY":"Bie\u017c\u0105cy rok podatkowy",//no i18n
	"Next\ FY":"Nast\u0119pny rok podatkowy",//no i18n
	"Current\ FQ":"Bie\u017c\u0105cy kwarta\u0142 podatkowy",//no i18n
	"Next\ FQ":"Nast\u0119ony kwarta\u0142 podatkowy",//no i18n
	"Previous\ FQ":"Poprzedni kwarta\u0142 podatkowy",//no i18n
	"crm.picklist.sample.text":"Przyk\u0142adowy tekst",//no i18n
	"crm.more.colors":"Wi\u0119cej kolor\u00f3w",//no i18n
	"crm.button.back.alone":"Wr\u00f3\u0107",//no i18n
	"crm.field.label.email":"E-mail",//no i18n
"crm.zia.nba.feature.label":"Nast\u0119pne najlepsze dzia\u0142anie",//no i18n
"Meeting":"Spotkanie",//no i18n
"Tomorrow":"Jutro",//no i18n
"crm.gdpr.notavailable.field":"Niedost\u0119pne",//no i18n
	"crm.setup.system.ziasimilarity":"Zalecenie podobie\u0144stwa",//no i18n
	"crm.gdpr.notavailable.field":"Niedost\u0119pne",//no i18n
	"crm.filter.label.all.products":"Wszystkie {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics", // NO I18N
	"crux.custom.field.greater.than.equalto":"{0} musi wynosić więcej niż lub być równe {1}.",
	"crux.users.selected.plural" : "Wybrano {0} użytkowników.",
	"crux.user.selected.singular" :"Wybrano {0} użytkownika.",
	"crux.criteria.empty.secondaryfield.module" : "Nie znaleziono pasującego pola {0} w module {1}",
	"crux.criteria.empty.secondaryfield" : "Nie ma innego pola {0} do porównania; wprowadź wartość do porównania.",
	"crux.logged.in.role.definition" : "Rola użytkownika, który inicjuje akcje na rekordzie",
	"zia.similarity.smartfilter.score":"Wynik podobie\u0144stwa",//no i18n
	"zia.similarity.smartfilter.records":"Podobne {0} z",//no i18n
	"zia.similarity.smartfilter.records.search":"Poka\u017c podobne {0} z",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Nie mo\u017cna zaznaczy\u0107 wi\u0119cej ni\u017c {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} musi wynosić więcej niż lub być równe {1}.",
	"crux.users.selected.plural" : "Wybrano {0} użytkowników.",
	"crux.user.selected.singular" :"Wybrano {0} użytkownika.",
	"crux.criteria.empty.secondaryfield.module" : "Nie znaleziono pasującego pola {0} w module {1}",
	"crux.criteria.empty.secondaryfield" : "Nie ma innego pola {0} do porównania; wprowadź wartość do porównania.",
	"crux.logged.in.role.definition" : "Rola użytkownika, który inicjuje akcje na rekordzie",
	"crux.max.limit.unselect" : "Nie można odznaczyć więcej niż {0} {1}.", //NO I18N
	"crux.existing.tag" : "\u201e{0}\u201d już wybrano" //NO I18N

}
